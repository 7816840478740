import React, { useState, useEffect } from 'react';

export default function ViewSchlImgGalryFrm() {
  const [image, setImage] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);


  const handlefromDateChange = (e) => {
    setFromDate(e.target.value);
  }
  const handletoDateChange = (e) => {
    setToDate(e.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault(); 
    const apiUrl = `/api/ViewImgGalryFrm?fromDate=${fromDate}&toDate=${toDate}`;
    fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setImage(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  };    

  useEffect(() => {
    fetch(`/api/ViewImgGalryFrm`)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setImage(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
        .catch(error => {
          
        });
  }, []); 

  const handleStatusChange = (clickedImage) => {
    const newStatus = clickedImage.gllryImgStatus === 0 ? 1: 0; 
    const updatedImages = image.map(img =>
      img.id === clickedImage.id ? { ...img, gllryImgStatus: newStatus } : img
    );
    setImage(updatedImages);
    fetch(`/api/fps-imggallery/${clickedImage.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: clickedImage.id, newStatus })
    })
      .then(response => response.json())
      .then(data => console.log('Status updated:', data))
      .catch(error => console.error('Error updating status:', error));
  };
  
  const toggleSelectImage = (clickedImage) => {
    if (selectedImages.some(image => image.id === clickedImage.id)) {
      setSelectedImages(selectedImages.filter(image => image.id !== clickedImage.id));
    } else {
      setSelectedImages([...selectedImages, clickedImage]);
    }
  };

  const handleDeleteBanner = () => {
    if (selectedImages.length === 0) {
      alert('Select at least one image to delete.');
      return;
    }

    const selectedImageIds = selectedImages.map(img => img.id);

    fetch(`/api/deleteSelectedGalleryImg`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: selectedImageIds })
    })
    .then(response => response.json())
    .then(data => {
       const updatedImages = image.filter(img => !selectedImageIds.includes(img.id));
       setImage(updatedImages);
       setSelectedImages([]);
     })
     .catch(error => console.error('Error deleting images:', error));
  };

  return (
    <>
      <div className='mx-2 my-3' >
    <div className='shadow-sm p-3 bg-white rounded mb-3'>
      <h1 className='text-center'>Image Gallery</h1>
      <form className='row  mx-0 d-flex justify-content-around m-form' onSubmit={handleSubmit}>
        <div className='col-md-4'>
          <label  className='form-label' htmlFor="title">From Date:</label>
          <input  className='form-input' type="Date" id="fromDate" name='fromDate' value={fromDate}  onChange={handlefromDateChange} required />
        </div>
        <div className='col-md-4'>
          <label  className='form-label' htmlFor="image">To Date:</label>
          <input  className='form-input' type="Date"  name="toDate" id="toDate"  value={toDate} onChange={handletoDateChange} required />
        </div>
        <div className='col-md-4 mt-4'>
        <button className='form-submit w-100' type="submit">Apply Filter</button>
        </div>
      </form>
    </div>
      <div className='container d-felx scrollOnXaxis '>
      <table className='table table-nowrap mb-0 mt-3'>
        <thead className='table-primary'>
          <tr>
            <th>Date</th>
            <th>Images</th>
            <th>Title</th>
            <th>Status</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {image.map(image => (
            <tr key={image.id}>
              <td>{image.uploadDate.slice(0, 10)}</td>
              <td><img src={`/api/Admin/Uploads/GlryImg/${image.gllryImgUrl}`} alt="Banner" className="banner-image " /></td>
              <td>{image.gllryImgTitle}</td>
              <td>
              <div className="form-check form-switch">
              <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckChecked-${image.id}`}
            checked={image.gllryImgStatus === 0 ? true : false} 
            onChange={() => handleStatusChange(image)} // Call the event handler
          />
          <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${image.id}`}>
            {image.gllryImgStatus === 0 ? 'Active' : 'Inactive'}
          </label>
          </div>
          </td>
          <td className='status-column '>
            <input type="checkbox" checked={selectedImages.some(selImage => selImage.id === image.id)} onChange={() => toggleSelectImage(image)} />
            </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <form method="GET" className='form1 my-4'>
        <button type="submit" className='btn btn-danger' onClick={handleDeleteBanner}  disabled={selectedImages.length === 0}>Delete Selected Image</button>
      </form>
      </div>
    </>
  );
}
