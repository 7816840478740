import React, {useState, useEffect} from 'react'
import { Link, useLocation } from "react-router-dom";
import logo from '../Assets/BrandLogo.png'

export default function Navbar() {
  const [clicked, setClicked] = useState('Home');
  const location = useLocation();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const TypeParam = searchParams.get('Type');
    if (TypeParam) {
      setClicked(TypeParam);
    }
  }, [location.search]); 

  const closeNavbar = () => {
    if (window.matchMedia('(max-width: 991px)').matches) {
      const navbarToggler = document.querySelector('.navbar-toggler');
      if (navbarToggler) {
        navbarToggler.click();
      }
    }
  };
  
  
  return (
    <>
    <nav className="navbar navbar-expand-lg bg-white fixed-top">
      <div className="container-fluid mx-0"><img src={logo} alt='school-logo'  className='mx-2 img-fluid' width='100'/>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button> 
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item mx-2" onClick={ () =>{closeNavbar();}}>
              <Link to='/?Type=Home' className={`nav-link ${clicked === 'Home' ? 'active' : 'fontColor'}`} aria-current="page">Home</Link>
            </li>
            <li className="nav-item mx-2" onClick={ () =>{closeNavbar();}}>
              <Link to='/AboutUs?Type=AboutUs' className={`nav-link ${clicked === 'AboutUs' ? 'active' : 'fontColor'}`} >About Us</Link>
            </li>
            <li className="nav-item mx-2" onClick={ () =>{closeNavbar();}}>
              <Link to='/Events?Type=Events' className={`nav-link ${clicked === 'Events' ? 'active' : 'fontColor'}`} >Events</Link>
            </li>
            <li className="nav-item dropdown mx-2">
              <a className={`nav-link dropdown-toggle ${clicked === 'Image' || clicked === 'Video' ? 'active' : 'dropdownFontColor'}`} href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Gallery
              </a>
              <ul className="dropdown-menu">
                <li onClick={closeNavbar}><Link className="dropdown-item fontColor" to="/ImageGallery?Type=Image">Image Gallery </Link></li>
                <li onClick={closeNavbar}><Link className="dropdown-item fontColor" to="/VideoGallery?Type=Video">Video Gallery</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown mx-2">
              <a className={`nav-link dropdown-toggle ${clicked === 'Bottles' || clicked === 'Pricing' ? 'active' : 'dropdownFontColor'}`} href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Products
              </a>
              <ul className="dropdown-menu">
                <li onClick={closeNavbar}><Link className="dropdown-item fontColor" to="/Pricing?Type=Bottles">Our Bottles</Link></li>
              </ul>
            </li>
          </ul>
          <Link  to='../Admin/LogIn' className='login-btn'>LogIn</Link>
        </div>
      </div>
    </nav>
    </>
  );
}

