import React, {useState, useEffect} from 'react';

export default function Dashboard() {

const [images, setImages] = useState([]);

  useEffect(() => {
      fetch(`/api/ImageSlider`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setImages(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
  }, []); 
    
return (
  <>
  <div style={{ paddingTop: "95px" }}> 
    <div id="carouselExampleIndicators" className="carousel slide " data-bs-ride="carousel">
    <div className="carousel-inner">
      {images.map((item, index) => (
        <div key={index} className={`carousel-item   carousel-image ${index === 0 ? 'active' : ''}`}>
          {item === 'Video' ? (
            <video className="responsive" autoPlay loop muted  playbackrate={2} >
              <source src='{Video}' type="video/mp4" width="600" height="200" />
            </video>
          ) : (
            <img src={`/api/Admin/Uploads/CarouselImg/${item.imgUrl}`} className="d-block img responsive" width="600" height="400" alt={`Slide ${index}`} />
          )}
        </div>
      ))}
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
  </div>
  </>
    );
}

