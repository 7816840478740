import React, { useEffect } from 'react'
import { useSession } from './SessionContext';
import { Link } from 'react-router-dom';
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import logo from '../Assets/BrandLogo.png'

export default function AdminPanel() {
    const { userLoginType, setUserLoginType } = useSession();


  useEffect(() => {
    var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (loginType) {
      setUserLoginType(loginType);
    }
  }, [setUserLoginType]);
  

  const handleLogout = () => {
    fetch('/Logout', {
      method: 'POST',
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = '/Admin/LogIn';
          window.localStorage.removeItem("isLoggedIn");
        }
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  const closeNavbar = () => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    if (navbarToggler) {
      navbarToggler.click();
    }
  };

  let inactivityTimeout;

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(handleLogout, 10 * 60 * 1000);
  };

  resetInactivityTimeout();
  document.addEventListener('mousemove', resetInactivityTimeout);
  document.addEventListener('keydown', resetInactivityTimeout);

  return (
    <>
    <nav className="navbar bg-white shadow-sm mb-1 flex-grow-1">
      <div className="container-fluid"><img src={logo} alt='school-logo'  className='mx-2 img-fluid d-md-none' width='100'/>
      <button className="navbar-toggler d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <h6 className='d-none d-md-block'>.</h6>
      <div className="dropdown nav-item mt-3 d-none d-md-flex">
           <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
             {userLoginType && <strong className='text-dark'>{userLoginType}</strong>}
           </p>
           <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
             <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
             <LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
           </ul>
      </div> 
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">tab
        <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img src={logo} alt='school-logo'  className='mx-2 img-fluid d-md-none' width='100'/></h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <FlagRoundedIcon style={{ marginRight: '8px' }}/>
              Banner Section
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/ImgUploadForm"><TimelineRoundedIcon style={{ marginRight: '8px' }}/>Add Banner</Link></li>
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/ViewBannerForm"><BubbleChartRoundedIcon style={{ marginRight: '8px' }}/>View/Add Banner</Link></li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <ImageRoundedIcon style={{ marginRight: '8px' }}/>
              Image Gallery
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/ImgGalryUpldFrm"><PhotoRoundedIcon style={{ marginRight: '8px' }}/>Add Events</Link></li>
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/ViewImgGalryFrm"><PhotoRoundedIcon style={{ marginRight: '8px' }}/>View Events</Link></li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <MovieRoundedIcon style={{ marginRight: '8px' }}/>
              Video Gallery
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/VideoUpldFrm"><VideocamRoundedIcon style={{ marginRight: '8px' }}/>Add Video</Link></li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <ArticleRoundedIcon style={{ marginRight: '8px' }}/>
              Event & Update
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/CreateEvents"><EventNoteRoundedIcon style={{ marginRight: '8px' }}/>Add Event</Link></li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <strong className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <ChatRoundedIcon style={{ marginRight: '8px' }}/>
              Client Enquiries
            </strong>
            <ul className="dropdown-menu">
              <li onClick={closeNavbar}><Link className="dropdown-item" to="/Admin/ViewEnquiryData"><ForumRoundedIcon style={{ marginRight: '8px' }}/>View Enquiry Details</Link></li>
            </ul>
          </li>
          <div className="dropdown nav-item mt-3">
           <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
             <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
             {userLoginType && <strong className='text-dark'>{userLoginType}</strong>}
           </p>
           <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
             <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
             <LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
           </ul>
          </div> 
        </ul>
      </div>
    </div>
  </div>
</nav>
      
    </>
  )
}
