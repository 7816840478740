import React, {useState, useEffect} from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Events() {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const toggleDatePicker = () => {
    setShowDatePicker(prevState => !prevState);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    toggleDatePicker(); 
  };

  const setToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const formattedDate =  month + '-' + day + '-' + year ;
    setSelectedDate(formattedDate)
  };

  useEffect(() => {
    const filterCriteria = {
      selectedDate,
    };

    const filterData = async () => {
      try {
        const response = await fetch(`/api/getEvents`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(filterCriteria),
        });
        if (response.ok) {
          const filteredData = await response.json();
          setData(filteredData);
        } else {
          console.error('Error fetching filtered data');
        }
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    };

    filterData();
  }, [selectedDate]);

  return (
    <>
     <div className="input-container" style={{ marginTop: "100px" }}>
       <input type="text" placeholder="Search For Events"/><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search srchIcon" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/></svg>
       <button type='button' className="button-style">Find Events</button>
       <ul className="d-flex flex-row mb-0 px-2 mx-3 d-none d-md-flex d-lg-flex">
          <li className="list-group-item mx-2">
            <a href='/?Type=Home' className='link actives' aria-current="page">List</a>
          </li>
          <li className="list-group-item mx-2">
            <a href='/'className='link' >Month</a>
          </li>
        </ul>
     </div>      
     <div className="Events-Selector">
       <ul className="d-flex flex-row mb-0 px-0">
          <li className={`list-group-item mx-2  ${currentPage === 1 ? 'disabled' : ''}`} onClick={handlePrevPage} role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={currentPage === 1 ? "#ccc" : "currentColor"} className="bi bi-chevron-left" viewBox="0 0 16 16"><path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/></svg>
          </li>
          <li className={`list-group-item mx-2 ${data.length <= indexOfLastItem ? 'disabled' : ''}`} onClick={handleNextPage} role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={data.length <= indexOfLastItem ? "#ccc" : "currentColor"} className="bi bi-chevron-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/></svg>
          </li>
        </ul>
        <button className='datePickerBtn' onClick={setToday}>Today</button>
        <button className="button-style mx-2" onClick={toggleDatePicker}>
        {/* <span>{selectedDate ? selectedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "Upcoming"}</span> */}
        <span>Upcoming</span>
        </button>
        {showDatePicker && (
        <DatePicker
          selected={selectedDate}
          onChange={date => handleDateSelect(date)}
          dateFormat="MM/dd/yyyy" 
          inline 
        />
      )}
     </div>      
     {currentItems.map(event => (
     <div className="Events" key={event.id}>
        <h2 className='h2 mb-0'>{new Date(event.startDate).toLocaleDateString('en-US', { month: 'long' })} - {new Date(event.startDate).toLocaleDateString('en-US', { year: 'numeric' })}</h2>
        <div className='row'>
            <div className='col-md-2 custom-md-2'>
                <div className='text-center mobileView'>
                    <span className='rno-icon'>{new Date(event.startDate).toLocaleDateString('en-US', { month: 'short' })}<strong className='rno-icon-item'>{new Date(event.startDate).toLocaleDateString('en-US', { day: 'numeric' })}</strong></span>
                </div>
            </div>
            <div className='col-md-10 my-3 custom-md-10'>
                <p className='mx-3 mb-1'>{new Date(event.startDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}{' @ '}{new Date(event.startTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC'  })} - {new Date(event.endDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}{' @ '}{new Date(event.endTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC'  })}</p>
                <strong className='mx-3'>{event.name}</strong>
                <p className='mx-3 mb-1'>{event.address}</p>
                <p className='mx-3'>{event.notes}</p>
            </div>
        </div>
     </div>      
      ))}
      <hr />
    </>
  )
}
