import React, {useState} from 'react'
import bottleRecyclin from '../Assets/bottle-recyclin.png'
import recycleBottleG from '../Assets/recycle-bottleG.png'
import water from '../Assets/water-plasticB.png'

export default function Pricing() {
  const [showModal, setShowModal] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientNo, setClientNo] = useState('');
  const [clientMssg, setClientMssg] = useState(''); 
  const [successMessage, setSuccessMessage] = useState('');

  const handleclientNameChange = (e) => {
    setClientName(e.target.value);
  };
  const handleclientEmailChange = (e) => {
    setClientEmail(e.target.value);
  };
  const handleclientNoChange = (e) => {
    setClientNo(e.target.value);
  };
  const handleclientMssgChange = (e) => {
    setClientMssg(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(`/api/ContactUs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientName, clientEmail, clientNo, clientMssg }),
      });
      
      if (response.ok) {
        setClientName('');
        setClientEmail('');
        setClientNo('');
        setClientMssg(''); 
        setShowModal(false);
        setSuccessMessage('Will Contact You Soon.');
      } else {
        console.log("error in uploading")
      }
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  }
  return (
    <>
      {showModal && (
        <div className="modal-backdrop show"></div>
      )}
      <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-1 mb-5 bg-white rounded">
            <div class="modal-header">
              <h5 class="modal-title">Drop Your Message</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
            </div>
            <div className="modal-body">
              <h3 style={ {textAlign:'center', color:'#2ec4b6'}}>Reach Out to Free Pani!</h3>
              <form className='php-email-form' onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="col form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"  value={clientName}  onChange={handleclientNameChange} required />
                    <div className="validate"></div>
                  </div>
                  <div className="col form-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" value={clientEmail}  onChange={handleclientEmailChange} required  />
                    <div className="validate"></div>
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="number" id="number" placeholder="Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" value={clientNo}  onChange={handleclientNoChange} required   />
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"  value={clientMssg}  onChange={handleclientMssgChange} required ></textarea>
                  <div className="validate"></div>
                </div>
                <div className="form-group d-none">
                  <input type="text" className="form-control" name="first_name" />
                  <div className="validate"></div>
                </div>
              <div className="text-center"><button type="button" className="btn btn-outline-danger"  onClick={handleSubmit}>Send Message</button></div>
            </form>
            </div>
            {successMessage && <span className='text-success'>{successMessage}</span>}
          </div>
        </div>
      </div>
      <div className='py-3' style={{ maxWidth: '100%', margin:'95px 0px 10px 0px',  backgroundColor:'#2ec4b6' }}>
        <h1 className='text-center' style={{color:'#fdfffc'}}>Bottle Capacity Choices & Configuration</h1>
        <h4 className='text-center' style={{color:'#e71d36'}}>#Capacity & Style</h4>
      </div>
      <div className='my-3 mx-5 text-center d-flex flex-column justify-content-center align-items-center'>
        <strong className='text-primary mb-4'>We provide a range of water bottle capacities, starting from 200ml and going up to 1 litre. Our selection includes a diverse array of designs, dimensions, and materials to cater to all preferences and requirements.</strong>
      <button type="button" className="btn btn-outline-success" onClick={() => setShowModal(true)} ><strong>Request a pricing proposal</strong></button>
      </div>
      <div className='my-3 mx-5 row'>
        <div className='col-md-4 d-flex flex-column justify-content-center align-items-center'>
          <img src='https://cdn-icons-png.flaticon.com/512/3778/3778764.png' alt='png' width={50} />
          <strong className='text-info mb-2 fs-2'>BPA FREE.</strong>
          <p className='text-center'>All of our bottles are the highest quality BPA free PET plastic available</p>
        </div>
        <div className='col-md-4 d-flex flex-column justify-content-center align-items-center'>
          <img src='https://cdn-icons-png.flaticon.com/512/1434/1434962.png' alt='png' width={50} />
          <strong className='text-info mb-2 fs-2'>Cost Competitive.</strong>
          <p>We offer the best pricing in the industry</p>
        </div>
        <div className='col-md-4 d-flex flex-column justify-content-center align-items-center'>
          <img src='https://cdn-icons-png.flaticon.com/512/3271/3271275.png' alt='png' width={50} />
          <strong className='text-info mb-2 fs-2'>Durability.</strong>
          <p className='text-center'>Our bottles are stronger comparability with available in local market</p>
        </div>
      </div>
      <div className='my-3 mx-5 row'>
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center image-container'>
          <img src='https://png.pngtree.com/png-vector/20220609/ourmid/pngtree-vector-water-plastic-bottles-set-png-image_4837471.png' alt='png' width={300} className="original-image" />
          <img src={water} alt='png' width={300} className="hover-image" />
        </div>
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center'>
          <strong className='text-dark mb-2 fs-2'>Cost-Effective Bottles</strong>
          <p>A bottle that's easy to grasp and features ridges for enhanced grip. Our preferred bottle style, crafted from premium BPA-free PET plastic, renowned for its durability and resistance to dents, deformation, and leakage upon opening.</p>
        </div>
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center'>
          <img src='https://cdn.vectorstock.com/i/preview-1x/27/07/bottle-vector-6712707.webp' alt='png' width={300} />
        </div>
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center'>
          <strong className='text-dark mb-2 fs-2'>Trendy Bottles</strong>
          <p className='text-center'>Trim bottle appearance.</p>
        </div>
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center image-container'>
          <img src={recycleBottleG} alt='png' width={300} className="original-image" />
          <img src={bottleRecyclin} alt='png' width={300} className="hover-image" />
        </div>
        <div className='col-md-6 d-flex flex-column justify-content-center align-items-center'>
          <strong className='text-dark mb-2 fs-2'>Recyclable Bottles</strong>
          <p className='text-center'>Our offerings include plastic-free alternatives composed of 100% recyclable and refillable paper bottles and caps. These environmentally conscious containers provide a sustainable solution, promoting eco-friendliness and reducing plastic waste. Opting for these innovative paper-based bottles and caps ensures both convenience and environmental responsibility, contributing to a greener future.</p>
        </div>
      </div>
    </>
  )
}
