import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../Assets/BrandLogos.png'
import facebook from '../Assets/logo/facebook.png'
import youtube from '../Assets/logo/youtube.png'

export default function Footer() {
  return (
    <>
    <footer className="pt-5 mt-4 container text-white" style={{ maxWidth: '100%', margin:'0px',  backgroundColor:'#2ec4b6' }}>
    <div className="row" style={{ backgroundColor:'#fdfffc'}}>
      <div className="col-md-2 offset-md-1 mb-3 pt-2 ">
        <h3 className='text-color'>Quick Links</h3>
        <ul className="nav flex-column ">
          <li className="nav-item mb-2 "><Link to="/?Type=Home" className="nav-link p-0 text-danger">Home</Link></li>
          <li className="nav-item mb-2"><Link to="/Pricing?Type=Bottles" className="nav-link p-0 text-dark">Our Bottles</Link></li>
          <li className="nav-item mb-2"><Link to="/Events?Type=Events" className="nav-link p-0 text-dark">Events</Link></li>
        </ul>
      </div>
      <div className="col-md-4 offset-md-1 mb-3 pt-2">
        <h3 className='text-color'>Reach Us</h3>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 text-dark">8/65 ARYA NAGAR, KANPUR, UTTAR PRADESH – 208002</Link></li>
          <li className="nav-item mb-2"><Link to="tel:++919125585333" className="nav-link p-0 text-dark">(+91) 912 558 5333</Link></li>
          <li className="nav-item mb-2"><Link to="mailto:shuklanurag@yahoo.com" className="nav-link p-0 text-dark">shuklanurag@yahoo.com</Link></li>
        </ul>
      </div>
      <div className="col-md-2 offset-md-1  mb-3 pt-2">
      <img src={logo} alt='school-logo'  className='mx-2 img-fluid' width='150' height='97'/>
      <div className="col-6 col-md-5 my-3 pt-2 ">
          <ul className="nav flex-row">
            <li className="nav-item mb-2"><Link to='https://www.facebook.com'><img src={facebook} width="32" height="32" className="rounded-circle me-2" alt='footer-logo'/></Link></li>
            <li className="nav-item mb-2"><Link to='https://www.youtube.com'><img src={youtube} width="32" height="32" className="rounded-circle me-2" alt='footer-logo'/></Link></li>
          </ul>
      </div>
      </div>
    </div>
    <div className="d-flex flex-column flex-sm-row justify-content-between pt-2 " style={{ backgroundColor:'#2ec4b6'}}>
      <p className='text-white text-center'>© FreePani, Inc. All rights reserved.</p>
      <p className='text-white text-center'><Link to='http://rayaninfosolutions.com/' className='nav-link'>Designed by: Rayan Info Solutions</Link></p>
    </div>
  </footer>
      <div className="whatsappWidget widgetPosition-Bottom-Right">
      <Link to='https://api.whatsapp.com/send?phone=+919125585333'><img src="https://image1.jdomni.in/jdomni_email/whatsapp_popup_2011271203.png?output-format=webp" alt='a' className="whatsappIcon" /></Link>
      </div>
    </>
  )
}
