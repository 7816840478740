import React, { useState } from 'react';

const SchlImgGalryUpldFrm = () => {

    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [errorMssg, setErrorMssg] = useState('Image size should not exceed 250KB.');
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    
    const handleImageChange = (e) => {
      const selectedImage = e.target.files[0];
      if (selectedImage) {
        setImage(selectedImage);
    
        const reader = new FileReader();
        reader.onload = (event) => {
          setImagePreview(event.target.result);
        };
        reader.readAsDataURL(selectedImage);
    
        if (selectedImage.size > 250 * 1024) {
          setErrorMssg('Image size exceeds 250KB limit.');
          return;
        } else {
          setErrorMssg(''); // Reset error message if the image size is within the limit
        }
      } else {
        setImage(null);
        setImagePreview(null);
        setErrorMssg(''); // Reset error message if no image is selected
      }
    };

    const removeSuccessMessage = () => {
      setSuccessMessage('');
      setShowModal(false);
    };
      
    const handleSubmit = async (e) => {
      console.log('handleImageChange called');
      e.preventDefault();
      if (isSubmitting) {
        return; 
      }
      setIsSubmitting(true); 
      setSuccessMessage('Image Uploaded successfully.');
      setShowModal(true);
      setTimeout(() => {
        removeSuccessMessage();
      }, 2000); 

      const formData = new FormData();
      formData.append('image1', image);
      formData.append('gllryImgTitle', title );
    
      try {
        const response = await fetch(`/api/GlryImg`, {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          setTitle('');
          setImage(null);
          setImagePreview(null);
          setErrorMssg('');
        } else {
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
      setIsSubmitting(false);
    }

    const [imagePreview, setImagePreview] = useState(null);

  return (
    <>
{showModal && (
    <div className="modal-backdrop show"></div>
  )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
            <div className="modal-header">
              <h5 className="modal-title">Success Message</h5>
            </div>
            <div className="modal-body">
              {successMessage && <p className='text-success'>{successMessage}</p>}
            </div>
          </div>
        </div>
    </div>
    <div className='container mt-5 '>
      <h2 className='text-center c2'>Upload Images</h2>
      <form className='form-container' onSubmit={handleSubmit}>
        <div>
          <label  className='form-label' htmlFor="title">Title:</label>
          <input  className='form-input' type="text" id="title" value={title}  onChange={handleTitleChange} required />
        </div>
        <div>
          <label  className='form-label' htmlFor="image">Image:</label>
          <input  className='form-input' type="file"  name="image1" id="image" accept="image/*" value={null} onChange={handleImageChange} required />
        </div>
        {errorMssg && <p style={{ color: 'red' }}>{errorMssg}</p>}
        <div className='my-2'>
          {imagePreview && <img src={imagePreview} alt="Selected"className='imgprv' style={{ maxWidth: '100%', maxHeight: '100%' }} />}
        </div>
        <button className='form-submit' type="submit">Upload Image</button>
      </form>
    </div>
    </>
  )
}

export default SchlImgGalryUpldFrm;

