import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from './Admin/SessionContext';
import Navbar from './component/Navbar';
import Dashboard from './component/Dashboard';
import Feature from './component/Feature';
import AboutUs from './component/AboutUs';
import ImgGallery from './component/ImgGallery';
import VideoGallery from './component/VideoGallery';
import ImageGallery from './component/ImageGallery';
import Events from './component/Events';
import Pricing from './component/Pricing';
import ContactUs from './component/ContactUs';
import Footer from './component/Footer';
import LogIn from './Admin/LogIn';
import AdminHome from './Admin/AdminHome';
import AdminPanel from './Admin/AdminPanel';
import ImgUploadForm from './Admin/ImgUploadForm';
import ViewBannerForm from './Admin/ViewBannerForm';
import ImgGalryUpldFrm from './Admin/ImgGalryUpldFrm';
import ViewImgGalryFrm from './Admin/ViewImgGalryFrm';
import VideoUpldFrm from './Admin/VideoUpldFrm';
import ViewEnquiryData from './Admin/ViewEnquiryData';
import CreateEvents from './Admin/CreateEvents';
import './App.css';
import './CSS/animation.css'

function App() {
  const loggedIn = localStorage.getItem("isLoggedIn");
  return (
    <Router>
    <SessionProvider>
      <Routes>
        <Route path="/" element={
          <>
            <Navbar />
            <Dashboard />
            <Feature />
            <ImgGallery />
            <ContactUs />
            <Footer />
          </>
        } />
        <Route path="/AboutUs" element={
          <>
            <Navbar />
            <AboutUs />
            <ContactUs />
            <Footer />
          </>
        } />
        <Route path="/Events" element={
          <>
            <Navbar />
            <Events />
            <ContactUs />
            <Footer />
          </>
        } />
        <Route path="/VideoGallery" element={
          <>
            <Navbar />
            <VideoGallery />
            <ContactUs />
            <Footer />
          </>
        } />
        <Route path="/ImageGallery" element={
          <>
            <Navbar />
            <ImageGallery />
            <ContactUs />
            <Footer />
          </>
        } />
        <Route path="/Pricing" element={
          <>
            <Navbar />
            <Pricing />
            <ContactUs />
            <Footer />
          </>
        } />
        <Route path="/Admin/LogIn" element={
          <>
            <LogIn />
          </>
        } />
         <Route  path="/Admin/AdminHome" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <ImgUploadForm />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/ImgUploadForm" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <ImgUploadForm />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/ViewBannerForm" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <ViewBannerForm />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/ImgGalryUpldFrm" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <ImgGalryUpldFrm />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/ViewImgGalryFrm" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <ViewImgGalryFrm />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/VideoUpldFrm" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <VideoUpldFrm />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/ViewEnquiryData" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <ViewEnquiryData />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
         <Route  path="/Admin/CreateEvents/:id?" element={
        <>   
          { loggedIn ? (
            <div className='app-container'>
            <AdminHome />
            <div className='content'>
              <AdminPanel />
              <CreateEvents />
            </div>
            </div>
          ) : (
            <LogIn />
          )}           
        </>
        } />
      </Routes>
    </SessionProvider>
  </Router>
  );
}

export default App;
