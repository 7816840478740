import React, {useState, useEffect} from 'react'
import { useNavigate, useParams  } from 'react-router-dom';

export default function CreateEvents() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [fromTime, setFromTime] = useState('');
    const [toDate, setToDate] = useState('');
    const [toTime, setToTime] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [notes, setNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [DeleteId, setDeleteId] = useState(''); 
    const [isDeleted, setIsDeleted] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); 
    const Navigate  = useNavigate();

    const handleFDateChange = (e) => {
        setFromDate(e.target.value);
    };
    const handleFTimeChange = (e) => {
        setFromTime(e.target.value);
    };
    const handleTDateChange = (e) => {
        setToDate(e.target.value);
    };
    const handleTTimeChange = (e) => {
        setToTime(e.target.value);
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const handleAddChange = (e) => {
        setAddress(e.target.value);
    };
    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };

    const removeSuccessMessage = () => {
        setSuccessMessage('');
        setShowModal(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) {
          return;
        }
        setIsSubmitting(true);
        setShowModal(true);
        setTimeout(() => {
          removeSuccessMessage();
        }, 1000); 
    
        if (id) {
          try {
            const response = await fetch(`/api/updateEvents/${id}`, {
              method: 'PUT', 
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ fromDate, fromTime, toDate, toTime, name, address, notes }),
            });
      
            if (response.ok) {
              setSuccessMessage('Events Updated successfully.');
              setFromDate('');
              setToDate('')
              setFromTime('');
              setToTime('')
              setName('')
              setAddress('');
              setNotes('')
              fetchData();
              Navigate(`/Admin/CreateEvents`)
            } else {
              console.error('Error updating Events');
            }
          } catch (error) {
            console.error('Error updating Events:', error);
          }
        } else {
          try {
            const response = await fetch(`/api/CreateEvent`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ fromDate, fromTime, toDate, toTime, name, address, notes }),
            });
    
            if (response.ok) {
              setSuccessMessage('Events Created successfully.');
              setFromDate('');
              setToDate('')
              setFromTime('');
              setToTime('')
              setName('')
              setAddress('');
              setNotes('')
              fetchData();
            } else {
              console.error('Error creating Events');
            }
          } catch (error) {
            console.error('Error creating Events:', error);
          }
        }
    
        setIsSubmitting(false);
    };

    const fetchData = () => {
        fetch(`/api/CreateEvent`)
          .then(response => response.json())
          .then(data => {
            const dataArray = data.recordset;
            if (Array.isArray(dataArray)) {
              setData(dataArray);
            } else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    };
      
    useEffect(() => {
        fetchData();
    }, []);

    const handleEditClick = (id) => {
       Navigate(`/Admin/CreateEvents/${id}`)
    };

    useEffect(() => {
        if (id) {
          fetch(`/api/updateEvent/${id}`)
            .then((response) => response.json())
            .then((result) => {
              if (result && result.length > 0) {
                const data = result[0]; 
                setFromDate(data.startDate ? data.startDate.slice(0, 10) : '');
                setFromTime(data.startTime ? data.startTime.slice(11, 16) : '');
                setToDate(data.endDate ? data.endDate.slice(0, 10) : '');
                setToTime(data.endTime.slice(11, 16) || '');
                setName(data.name || '')
                setAddress(data.address || '')
                setNotes(data.notes || '')
              } else {
                console.log('No data received');
              }
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
          } else {
            setFromDate('');
            setFromTime('');
            setToDate('');
            setToTime('');
            setName('');
            setAddress('');
            setNotes('');
          }
      }, [id]);

    const handleDelete = async (id) => {
        try {
          const response = await fetch(`/api/DeleteEvents/${id}`, {
            method: 'DELETE',
          });
    
          if (response.ok) {
            setData((prevData) => prevData.filter(item => item.id !== id));
            setSuccessMessage('Events Deleted successfully.');
            setShowModal(true);
            setTimeout(() => {
              removeSuccessMessage();
            }, 2000);
            setIsDeleted(false);
          } else {
            console.error('Error deleting Events');
          }
        } catch (error) {
          console.error('Error deleting Events:', error);
        }
    };
    
    const handleConfirmation = async (id) => {
        setIsDeleted(true);
        setDeleteId(id)
    };

    const handleCancel = async () => {
      setIsDeleted(false);
      setDeleteId('')
    };
      
      
  return (
    <>
        {showModal && (
          <div className="modal-backdrop show"></div>
        )}
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                <div className="modal-header">
                  <h5 className="modal-title">Success Message</h5>
                </div>
                <div className="modal-body">
                  {successMessage && <p className='text-success'>{successMessage}</p>}
                </div>
              </div>
            </div>
        </div>
        <div className='shadow-sm p-3 bg-white rounded mb-3 mx-2'>
          <form className='row' onSubmit={handleSubmit}>
            <div className='col-md-2 col-6'>
              <label  className='form-label' htmlFor="title">From Date:</label>
              <input  className='form-input' type="Date" value={fromDate}  onChange={handleFDateChange} required />
            </div>
            <div className='col-md-2 col-6'>
              <label  className='form-label' htmlFor="title">Time:</label>
              <input  className='form-input' type="Time" value={fromTime}  onChange={handleFTimeChange} required />
            </div>
            <div className='col-md-2 col-6'>
              <label  className='form-label' htmlFor="title">To Date:</label>
              <input  className='form-input' type="Date" value={toDate}  onChange={handleTDateChange} required />
            </div>
            <div className='col-md-2 col-6'>
              <label  className='form-label' htmlFor="title">Time:</label>
              <input  className='form-input' type="Time" value={toTime}  onChange={handleTTimeChange} required />
            </div>
            <div className='col-md-4'>
              <label  className='form-label' htmlFor="title">Name Of Event:</label>
              <input  className='form-input' type="Text" value={name}  onChange={handleNameChange} required />
            </div>
            <div className='col-md-5'>
              <label  className='form-label' htmlFor="title">Address:</label>
              <input  className='form-input' type="Text" value={address}  onChange={handleAddChange} required />
            </div>
            <div className='col-md-5'>
              <label  className='form-label' htmlFor="title">Notes:</label>
              <input  className='form-input' type="Text" value={notes}  onChange={handleNotesChange} required />
            </div>
            <div className='col-md-2 mt-4'>
            <button className='form-submit w-100' type="submit">{id ? 'Update' : 'Submit'}</button>
            </div>
          </form>
        </div>
        <div className='shadow mb-5 rounded scrollOnXaxis mx-2' style={{ maxHeight: '100vh', overflowY: 'auto', }}>
        <table className='table table-nowrap mb-0 mt-0'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>From Date</th>
              <th>To Date</th>
              <th>Address</th>
              <th>Places</th>
              <th>Notes</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td>{item.startDate.slice(0, 10)}</td>
                <td>{item.endDate.slice(0, 10)}</td>
                <td>{item.name}</td>
                <td>{item.address}</td>
                <td>{item.notes}</td>
                <td><p className='badge badge-warning-lighten mx-2' onClick={() => handleEditClick(item.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg></p></td>
                <td> <p className='badge badge-danger-lighten mx-2' onClick={() => handleConfirmation(item.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/></svg></p></td>              
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        {isDeleted && (
          <div className="modal-backdrop show"></div>
        )}
        <div className={`modal fade ${isDeleted ? 'show' : ''}`} style={{ display: isDeleted ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content shadow-lg p-3 mb-5 bg-white rounded">
                <div className="modal-header">
                  <h5 className="modal-title">Delete <strong>Events?</strong></h5>
                </div>
                <div className="modal-body">
                  <p>This Will Delete Events <span className='text-danger'>Permannently</span></p>
                  <div className='d-flex flex-row-reverse '>
                  <button className='btn btn-danger mx-2' onClick={() => handleDelete(DeleteId)}>Delete</button>
                  <button className='btn btn-secondary mx-2' onClick={handleCancel}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </>
  )
}
