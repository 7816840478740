import React,{useState} from 'react';
import add from '../Assets/Blue and Beige Illustrative Music YouTube Intro.mp4'
import bottle from '../Assets/bottle.png'
import '../App.css'

export default function Feature() { 
  const [showModal, setShowModal] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientNo, setClientNo] = useState('');
  const [clientMssg, setClientMssg] = useState(''); 
  const [successMessage, setSuccessMessage] = useState('');

  const handleclientNameChange = (e) => {
    setClientName(e.target.value);
  };
  const handleclientEmailChange = (e) => {
    setClientEmail(e.target.value);
  };
  const handleclientNoChange = (e) => {
    setClientNo(e.target.value);
  };
  const handleclientMssgChange = (e) => {
    setClientMssg(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(`/api/ContactUs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientName, clientEmail, clientNo, clientMssg }),
      });
      
      if (response.ok) {
        setClientName('');
        setClientEmail('');
        setClientNo('');
        setClientMssg(''); 
        setShowModal(false);
        setSuccessMessage('Will Contact You Soon.');
      } else {
        console.log("error in uploading")
      }
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  }

  return (
   <>
    {showModal && (
        <div className="modal-backdrop show"></div>
    )}
    <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-lg p-1 mb-5 bg-white rounded">
          <div class="modal-header">
            <h5 class="modal-title">Drop Your Message</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
          </div>
          <div className="modal-body">
            <h3 style={ {textAlign:'center', color:'#2ec4b6'}}>Reach Out to Free Pani!</h3>
            <form className='php-email-form' onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="col form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"  value={clientName}  onChange={handleclientNameChange} required />
                  <div className="validate"></div>
                </div>
                <div className="col form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" value={clientEmail}  onChange={handleclientEmailChange} required  />
                  <div className="validate"></div>
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="number" id="number" placeholder="Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" value={clientNo}  onChange={handleclientNoChange} required   />
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"  value={clientMssg}  onChange={handleclientMssgChange} required ></textarea>
                <div className="validate"></div>
              </div>
              <div className="form-group d-none">
                <input type="text" className="form-control" name="first_name" />
                <div className="validate"></div>
              </div>
            <div className="text-center"><button type="button" className="btn btn-outline-danger"  onClick={handleSubmit}>Send Message</button></div>
          </form>
          </div>
          {successMessage && <span className='text-success'>{successMessage}</span>}
        </div>
      </div>
    </div>
		<div className="elementor-widget-container my-4">
			<div className="tm-modern-heading">
				<div className="heading-primary-wrap">
			      <h2 className="heading-primary elementor-heading-title">Water Has To Be Free,</h2></div>
				<div className="heading-divider my-3"></div>
				<div className="heading-description-wrap">
			    <div className="heading-description">
				    <p  className="heading-description">hence why FREEPANI</p></div>
		    </div>
		  </div>
		</div>
   <div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1200px' }}>
   <div className=" mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
    <div className="col-md-5 d-flex align-items-center order-md-2 order-1">
    <video className="video" autoPlay loop  controls >
      <source src={add} className="img-fluid rounded-start" alt="..." />
    </video>
    </div>
    <div className="col-md-7 order-md-2 order-1">
      <div className="card-body px-4">
        <h5>FREEPANI is an innovative advertising platform.</h5>
        <p className="">We print ads on the packaging. The revenue from the ads covers all costs, including our profit and a ₹1 donation to charity.</p>
        <ul>
          <li><i className="bi bi-check-circle"></i>For instance,</li>
          <li><i className="bi bi-check-circle"></i>If you're watching cricket and an ad comes on, it can be quite irritating.</li>
          <li><i className="bi bi-check-circle"></i>If you're walking and someone gives you a flyer or a leaflet, 99% of people either throw it away or don’t take it.</li>
          <li><i className="bi bi-check-circle"></i>Instagram, Facebook, and YouTube accounts are free, yet they make money and cover their many expenses. How do they do it? By showing ads to you and me.</li>
          <li><i className="bi bi-check-circle"></i>Ads are often annoying and can cause stress, which is why there is a skip button on platforms like YouTube.</li>
        </ul>
        <p>FreePani transforms ads into a refreshing experience. Instead of approaching people with your brand's advertisement, they come to you. With FreePani, we place advertisements on water bottles, allowing people to receive these bottles completely free of charge. This way, they not only get a free bottle but also have the opportunity to see and appreciate your ads, potentially thanking you or asking for more.</p>
      </div>
    </div>
    </div>
    </div>
   <div className="mb-1 mt-3 bg-light text-dark">
   <div className="row g-0">
   <div className="col-md-4 d-flex align-items-center justify-content-center order-md-2 order-1">
    <div className="imgContainer"><img className="wave" src="https://i.pinimg.com/originals/64/91/54/6491542a239217a7d44bfba368cd06c8.png" alt=""/><img className="bottle" src={bottle} alt=""/><img className="wave delay" src="https://i.pinimg.com/originals/64/91/54/6491542a239217a7d44bfba368cd06c8.png" alt=""/><img className="wave delay" src="https://i.pinimg.com/originals/64/91/54/6491542a239217a7d44bfba368cd06c8.png" alt=""/>
    </div>
    </div>
    <div className="col-md-8 order-md-1 order-2">
      <div className="card-body px-4">
        <h5>Quenching Thirst, Sparking Interest</h5>
        <p className=""> Engage your customers with eye-catching branded water bottles, creating lasting impressions while promoting hydration and sustainability.</p>
        <p>At FreePani, we believe in more than just advertising – we believe in making a difference. By partnering with us, you're not only promoting your brand but also supporting our mission to provide free water to those who need it most. Join us in quenching thirsts, sparking interest, and making a positive impact on communities worldwide. Choose FreePani and hydrate your brand while helping to solve the global water crisis.</p>
        <p className="card-text"><small className="text-body-secondary">Thank You</small></p>
        <button className="submit-button"  onClick={() => setShowModal(true)} >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 24 24">
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"></path>
          </svg>
          <span className="hidden-text">Advertise Your Brand</span>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    <div className='py-3' style={{ maxWidth: '100%', margin:'10px 0px',  backgroundColor:'#2ec4b6' }}>
      <h1 className='text-center' style={{color:'#fdfffc'}}>Revitalizing marketing that pays it forward!</h1>
      <h4 className='text-center' style={{color:'#e71d36'}}>#WaterForAll</h4>
    </div>
   </>
  );
}
