import React from 'react'
import aboutUS from '../Assets/Blue and Beige Illustrative Music YouTube Intro.mp4'
import addVideo from '../Assets/AboutUS.png'

export default function AboutUs() {
  return (
    <>
     <div style={{ paddingTop: "95px" }}> 
      <div className='container carouselImg' style={{ maxWidth: '1400px' }}>
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <video className="responsiveVideo" autoPlay loop muted >
              <source src={aboutUS} type="video/mp4" width="600" height="200" />
            </video>
          </div>
        </div>
      </div>
     </div>
    <div className='container my-3 py-1 bg-light text-dark shadow-lg p-3 mb-5 bg-white rounded' style={{ maxWidth: '1200px' }}>
      <div className=" mb-1 mt-3 bg-light text-dark">
        <div className="row g-0">
          <div className="col-md-8">
            <div className="card-body">
              <h2 style={{ color: '#e71d36', fontFamily: 'Poppins'}}>About Our Mission.</h2>
              <p className="">Welcome to <span style={{fontFamily: 'cursive', fontWeight: '200'}}>FREEPANI</span>, where we believe in the power of advertising to make a positive impact on communities while promoting sustainability. We are dedicated to revolutionizing the way businesses connect with their target audience by utilizing eco-friendly advertising mediums.
              </p>
              <h6 style={{color: '#e71d36', fontFamily: 'Poppins'}}>Promoting Sustainable Advertising:</h6>
              <p>
              We aim to reduce waste and promote sustainability by offering businesses the opportunity to advertise on reusable water bottles. By distributing these bottles free to the public, we encourage individuals to adopt eco-friendly habits while providing businesses with a platform to showcase their brand in a socially responsible way.
              </p>
              <h6 style={{color: '#e71d36', fontFamily: 'Poppins'}}>Supporting Community Organizations:</h6>
              <p>
              We partner with organizations dedicated to social causes and community development. The funds raised through advertising on our water bottles directly support these organizations, allowing them to continue their vital work and make a difference in the lives of those they serve.
              </p>
              <h2 style={{ color: '#e71d36', fontFamily: 'Poppins'}}>How It Works</h2>
              <p>
              Businesses can choose to advertise on our water bottles, reaching a wide audience while contributing to a greener future. Each bottle is carefully designed to feature the advertiser's logo, message, or promotional content, ensuring maximum visibility and impact.rong values, leadership qualities, and a sense of community. We encourage our students to explore their talents and interests through a wide range of extracurricular activities, ensuring a well-rounded education.
              </p>
              <p>
              Thank you for visiting <span style={{fontFamily: 'cursive'}}>FREEPANI</span>. We're excited to embark on this journey with you!
              </p>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <div className="d-flex justify-content-center shadow my-2 rounded mx-auto">
              <img src={addVideo} alt='AbotUs' className="img-fluid rounded-start" />  
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
