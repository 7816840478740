import React, { useState, useEffect } from 'react';

export default function ViewEnquiryData() {
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
  
    const handlefromDateChange = (e) => {
      setFromDate(e.target.value);
    }
    const handletoDateChange = (e) => {
      setToDate(e.target.value);
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      
      const apiUrl = `/api/ViewEnquiryData?fromDate=${fromDate}&toDate=${toDate}`;
      console.log('API URL:', apiUrl);
      fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setData(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
        .catch((error) => {
          console.error('Error fetching data:', error);
          // Handle the error
        });
    };    
  
    useEffect(() => {
      fetch(`/api/ViewEnquiryData`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setData(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
    }, []);      
  
    return (
      <>
        <div className='container' >
       <div className='shadow-sm p-3 bg-white rounded mb-3'>
       <h6 className='text-center'>Data Enquiries</h6>
        <form className='row  mx-0 d-flex justify-content-around' onSubmit={handleSubmit} >
          <div className='col-md-2'>
            <label  className='form-label' htmlFor="title">From Date:</label>
          </div>
          <div className='col-md-3 mb-2'>
            <input  className='form-input' type="Date" id="fromDate" name='fromDate' value={fromDate}  onChange={handlefromDateChange} required />
          </div>
          <div className='col-md-2'>
            <label  className='form-label' htmlFor="image">To Date:</label>
          </div>
          <div className='col-md-3 mb-2'>
            <input  className='form-input' type="Date"  name="toDate" id="toDate"  value={toDate} onChange={handletoDateChange} required />
          </div>
          <div className='col-md-2'>
          <button className='form-submit' type="submit">Apply Filter</button>
          </div>
        </form>
       </div>
        <div className='d-felx'>
        <div className='shadow mb-5 rounded scrollOnXaxis' style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <table className='table table-nowrap mb-0 mt-0'>
          <thead className='table-primary' style={{ position: 'sticky', top: '0', zIndex: '1', marginTop: '1rem'}}>
            <tr>
              <th>Date</th>
              <th>Client Name</th>
              <th>E-mail</th>
              <th>Mobile No</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
              <tr key={index}>
                <td>{item.enquiryDate && item.enquiryDate.slice(0, 10)}</td>
                <td>{item.clientName}</td>
                <td>{item.clientEmail}</td>
                <td>{item.clientNo}</td>
                <td>{item.clientMssg}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
        </div>
      </>
    );
}
